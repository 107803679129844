import React, { useState, useRef } from "react";
import "./form.css";

function App() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const formRef = useRef(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData(event.target);

      // Set the subject field explicitly
      formData.set("subject", "Hirdramani Sustainability Contacts Us Form");
      formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

      // Convert FormData to JSON
      const object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      const json = JSON.stringify(object);

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      if (res.ok) {
        const response = await res.json();
        if (response.success) {
          // Set the success message and clear form
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage(""); // Clear any previous error message
          formRef.current.reset(); // Reset the form fields
          window.location.href = formData.get("redirect");
        } else {
          setErrorMessage("Form submission failed. Please try again.");
          setSuccessMessage(""); // Clear any previous success message
        }
      } else {
        setErrorMessage("Form submission failed. Please try again.");
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={onSubmit} ref={formRef}>
        <div className="form-row">
          <div className="form-group">
            <label className="contact-form">Name:</label>
            <input type="text" name="name" required className="inputStyle" />
          </div>
          <div className="form-group">
            <label className="contact-form">Phone Number:</label>
            <input type="text" name="phone" required className="inputStyle" />
          </div>
        </div>
        <label className="contact-form">Email:</label>
        <input type="email" name="email" required className="inputStyle" />
        <label className="contact-form">Enter your Inquiry:</label>
        <textarea
          name="inquiry"
          rows="9"
          required
          className="textareaStyle"
        ></textarea>
        <input
          type="hidden"
          name="ccemail"
          value="imanthi.perera@hirdaramani.com; manindri.bandaranayake@hirdaramani.com; admin@3cs.lk"
        />
        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />
        <div className="form-footer">
          <button type="submit" className="btn submitButton btn-lg" id="button">
            Submit
          </button>
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      </form>
    </div>
  );
}

export default App;
